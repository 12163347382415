import { createAsyncThunk, createReducer } from "@reduxjs/toolkit";
import axios from "axios";
import { URL_FUSIONPBX } from "../../config/apiBaseURL";

// Define el estado inicial
const initialState = {
  extensions: [],
  error: null,
  loading: false,
};

// Definir acciones asíncronas
export const getAllExtensions = createAsyncThunk(
  "extensions/getAllExtensions",
  async () => {
    try {
      const response = await axios.get(
        `${URL_FUSIONPBX}/extensions/serv1/getAllExtensions/resume`
      );
      return response.data;
    } catch (error) {
      throw Error(error.response.data.message);
    }
  }
);

export const getAllExtensionsByDomain = createAsyncThunk(
  "extensions/getAllExtensionsByDomain",
  async ({ domainUUID, filter = null }) => {
    try {
      const response = await axios.get(
        filter
          ? `${URL_FUSIONPBX}/extensions/serv1/getAllExtensionsByDomain/${domainUUID}/${filter}`
          : `${URL_FUSIONPBX}/extensions/serv1/getAllExtensionsByDomain/${domainUUID}/`
      );
      return response.data;
    } catch (error) {
      throw Error(error.response.data.message);
    }
  }
);

export const getExtensionByUUID = createAsyncThunk(
  "extensions/getExtensionByUUID",
  async ({ extension_uuid, filter = null }) => {
    try {
      const response = await axios.get(
        filter
          ? `${URL_FUSIONPBX}/extensions/serv1/getExtension/${extension_uuid}/${filter}`
          : `${URL_FUSIONPBX}/extensions/serv1/getExtension/${extension_uuid}`
      );
      return response.data;
    } catch (error) {
      throw Error(error.response.data.message);
    }
  }
);

// Reducer para las extensiones
const extensionsReducer = createReducer(initialState, {
  [getAllExtensions.pending]: (state) => {
    state.loading = true;
    state.error = null;
  },
  [getAllExtensions.fulfilled]: (state, action) => {
    state.loading = false;
    state.extensions = action.payload;
  },
  [getAllExtensions.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [getAllExtensionsByDomain.pending]: (state) => {
    state.loading = true;
    state.error = null;
  },
  [getAllExtensionsByDomain.fulfilled]: (state, action) => {
    state.loading = false;
    state.extensions = action.payload;
  },
  [getAllExtensionsByDomain.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [getExtensionByUUID.pending]: (state) => {
    state.loading = true;
    state.error = null;
  },
  [getExtensionByUUID.fulfilled]: (state, action) => {
    state.loading = false;
    state.extensions = action.payload;
  },
  [getExtensionByUUID.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
});

export default extensionsReducer;
