import React, { useEffect, useState } from "react";
import Loading from "../../../commons/general/Loading";
import {
  Box,
  Grid,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Link as LinkRouter } from "react-router-dom";
import TableQuickDial from "./TableQuickdial";
import TableCustomerCalls from "../Gest Customer/TableCustomerCalls";
import axios from "axios";
import { URL_FUSIONPBX } from "../../../config/apiBaseURL";

const SoftphoneDetail = ({ user }) => {
  const [calls, setCalls] = useState([]);

  useEffect(() => {
    axios
      .get(
        `${URL_FUSIONPBX}/calls/serv1/getCallsByExtension/${user.company.domain.domain_uuid}/${user.extension.extension_uuid}`
      )
      .then((resp) => {
        setCalls(resp.data);
      });
  }, [
    user.company.domain.domain_uuid,
    user.extension.extension,
    user.extension.extension_uuid,
  ]);

  // Ensuring useBreakpointValue is called directly in the component
  const templateColumns = useBreakpointValue({ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" });

  if (!user) {
    return <Loading />;
  }

  return (
    <Box p={{ base: 2, md: 4 }}>
      <Grid
        templateColumns={templateColumns}
        gap={4}
        mb="4"
        textAlign={{ base: "left", md: "center" }}
      >
        <Text fontWeight={"bold"}>Name: {user.name}</Text>
        <Text fontWeight={"bold"}>Email: {user.email}</Text>
        <Text fontWeight={"bold"}>Phone: {user.phone}</Text>
        <Text fontWeight={"bold"}>Address: {user.address}</Text>
        <Text fontWeight={"bold"}>
          Nº Extension: {user.extension.extension}
        </Text>
        <Text fontWeight={"bold"}>
          Outbound Caller Name:{" "}
          {user.extension.outbound_caller_id_name
            ? user.extension.outbound_caller_id_name
            : " - "}
        </Text>
        <Text fontWeight={"bold"}>
          Effective CID Name:{" "}
          {user.extension.effective_caller_id_name
            ? user.extension.effective_caller_id_name
            : " - "}
        </Text>
        <Text fontWeight={"bold"}>
          Company:
          <LinkRouter to={`/client/${user.company._id}`}>
            <Text color={"nevtis.1"} as={"u"} ml={2}>
              {user.company.companyName}
            </Text>
          </LinkRouter>
        </Text>
      </Grid>
      <Tabs variant="soft-rounded" colorScheme="orange" mt={10}>
        <TabList>
          <Tab>Calls</Tab>
          <Tab>Quick Dial</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <TableCustomerCalls calls={calls} />
          </TabPanel>
          <TabPanel>
            <TableQuickDial user={user} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default SoftphoneDetail;
