import { getAllClient } from "../store/reducers/client.reducer";
import { getAllServices } from "../store/reducers/service.reducer";
import { getAllPbxExtensionByCompany } from "../store/reducers/pbxExtensions.reducer";
import { getAllSoftphoneUserByCompany } from "../store/reducers/softphoneUser.reducer";
import { getAllSoftphoneUser } from "../store/reducers/softphoneUser.reducer";
import { getAllAdmin } from "../store/reducers/admin.reducer";
import { getAllProposar } from "../store/reducers/proposar.reducer";
import { getAllExtensions } from "../store/reducers/extensionFusionPbx.reducer";
import { getAllDomains } from "../store/reducers/domainFusionPbx.reducer";
import { getAllSeller } from "../store/reducers/seller.reducer";
import { findUserChats } from "../store/reducers/chat.reducer";
import { getAllServiceCalls } from "../store/reducers/serviceCalls.reducer";
import { getAllTickets } from "../store/reducers/ticket.reducer";

const roleActions = {
  admin: (dispatch, user) => {
    dispatch(getAllAdmin());
    dispatch(getAllClient());
    dispatch(getAllSoftphoneUser());
    dispatch(getAllExtensions());
    dispatch(getAllDomains());
    dispatch(getAllSeller());
    dispatch(getAllServiceCalls());
    dispatch(getAllTickets());
    dispatch(findUserChats(user._id));
  },
  client: (dispatch, user) => {
    dispatch(getAllServices());
    dispatch(getAllPbxExtensionByCompany(user._id));
    dispatch(getAllSoftphoneUserByCompany(user._id));
  },
  seller: (dispatch) => {
    dispatch(getAllServices());
    dispatch(getAllClient());
    dispatch(getAllProposar());
  },
  softphoneUsers: (dispatch, user) => {
    dispatch(getAllSoftphoneUserByCompany(user.company._id));
  },
};
export function handleUserRole(user, dispatch) {
  if (!user || !user.role) {
    return;
  }

  const { role } = user;
  const actionFunction = roleActions[role];

  if (actionFunction) {
    actionFunction(dispatch, user);
  }
}
