import React, { useEffect, useState, useCallback } from "react";
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from "recharts";
import { Box, Heading } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { URL_FUSIONPBX } from "../../../config/apiBaseURL";
import { servers } from "../../../config/serversFusionPbx";
import axios from "axios";

const ExtensionGraph = () => {
  const { customerId } = useParams();
  const allCustomers = useSelector((state) => state.clients);
  const customer = allCustomers.find((item) => item._id === customerId);
  const [allExtensions, setAllExtensions] = useState({
    extensions: [],
  });

  const getServerValue = useCallback(() => {
    const server = servers.find((server) => server.url === customer?.serverURL);
    return server ? server.value : null;
  }, [customer?.serverURL]);

  useEffect(() => {
    if (!getServerValue()) return;

    axios
      .get(
        `${URL_FUSIONPBX}/extensions/${getServerValue()}/getAllExtensions/resume`
      )
      .then((res) => {
        if (res.data && Array.isArray(res.data.extensions)) {
          setAllExtensions(res.data);
        } else if (Array.isArray(res.data)) {
          setAllExtensions({ extensions: res.data });
        } else {
          console.error("Extensions data is missing or malformed:", res.data);
        }
      })
      .catch((err) => {
        console.error("Error fetching extensions:", err);
      });
  }, [customer, getServerValue]);

  const allSoftphoneUsers = useSelector((state) => state.softphoneUsers);
  const extensionsByCustomer =
    customer?.domain && allExtensions?.extensions
      ? allExtensions.extensions.filter(
          (item) => item.domain_uuid === customer.domain.domain_uuid
        )
      : [];

  const extensionesAsignadas = extensionsByCustomer.map((extension) => {
    const softphone = allSoftphoneUsers.find(
      (user) => user.extension.extension_uuid === extension.extension_uuid
    );
    return {
      extension_number: extension.extension,
      effective_caller_id_name: extension.effective_caller_id_name,
      outbound_caller_id_number: extension.outbound_caller_id_number,
      outbound_caller_id_name: extension.outbound_caller_id_name,
      softphone_user: softphone ? softphone : null,
    };
  });

  const softphoneCount = extensionesAsignadas.filter(
    (item) => item.softphone_user
  ).length;
  const nonSoftphoneCount = extensionesAsignadas.filter(
    (item) => !item.softphone_user
  ).length;
  const COLORS = ["rgba(255,134,0,0.8)", "rgba(255,134,0,0.5)"];

  const data = [
    { name: "Softphone Users", value: softphoneCount },
    { name: "Available", value: nonSoftphoneCount },
  ];

  return (
    <Box width="100%" height={260} textAlign="center">
      <Heading color={"gray.500"} size={"lg"}>
        Extensions
      </Heading>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel()}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default ExtensionGraph;

const renderCustomizedLabel =
  () =>
  ({ cx, cy, midAngle, innerRadius, outerRadius, value, index }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        dominantBaseline="central"
        fontSize={"20px"}
        fontWeight={"bold"}
      >
        {`${value}`}
      </text>
    );
  };
