import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import Tickets from "../serviceCalls/Tickets";
import LiveCallPopup from "./LiveCallsPopUp";
import Home from "../callsDetails/Home";

const HomeLiveCalls = () => {
  return (
    <Box px={4} mt={20}>
      <LiveCallPopup />
      <Flex>
        <Box mt={20} px={10}>
          <Tickets />
        </Box>
        <Home />
        <Box />
      </Flex>
    </Box>
  );
};

export default HomeLiveCalls;
