import { createAsyncThunk, createReducer } from "@reduxjs/toolkit";
import axios from "axios";
import { URL_FUSIONPBX } from "../../config/apiBaseURL";

// Define el estado inicial
const initialState = {
  domains: [],
  error: null,
  loading: false,
};

// Definir acciones asíncronas
export const getAllDomains = createAsyncThunk(
  "domains/getAllDomains",
  async () => {
    try {
      const response = await axios.get(
        `${URL_FUSIONPBX}/domains/serv1/getAllDomain/resume`
      );
      return response.data;
    } catch (error) {
      throw Error(error.response.data.message);
    }
  }
);

export const getDomainByUUID = createAsyncThunk(
  "domains/getDomainByUUID",
  async ({ uuid, filter = null }) => {
    try {
      const response = await axios.get(
        filter
          ? `${URL_FUSIONPBX}/domains/serv1/getDomain/${uuid}/${filter}`
          : `${URL_FUSIONPBX}/domains/serv1/getDomain/${uuid}`
      );
      return response.data;
    } catch (error) {
      throw Error(error.response.data.message);
    }
  }
);

// Reducer para los dominios
const domainsReducer = createReducer(initialState, {
  [getAllDomains.pending]: (state) => {
    state.loading = true;
    state.error = null;
  },
  [getAllDomains.fulfilled]: (state, action) => {
    state.loading = false;
    state.domains = action.payload;
  },
  [getAllDomains.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [getDomainByUUID.pending]: (state) => {
    state.loading = true;
    state.error = null;
  },
  [getDomainByUUID.fulfilled]: (state, action) => {
    state.loading = false;
    state.domains = action.payload;
  },
  [getDomainByUUID.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
});

export default domainsReducer;
