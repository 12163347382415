import { useState, useEffect } from "react";
import axios from "axios";

const useCalls = (startSelectedDate, formattedNextDay, endSelectedDate) => {
  const [calls, setCalls] = useState([]);

  useEffect(() => {
    const obtenerLlamadas = async () => {
      try {
        const response = await axios.post(
          `https://api.nevtis.com/fusionpbx/calls/getCallsByDomainAndDate/f1d51904-3b02-4453-8536-28e7c1e8290a`,
          {
            startStamp: startSelectedDate,
            endStamp: formattedNextDay,
          }
        );
        setCalls(response.data);
      } catch (error) {
        console.error("Error al obtener las llamadas:", error);
      }
    };

    obtenerLlamadas();
    const intervalo = setInterval(() => {
      obtenerLlamadas();
    }, 30 * 1000);

    return () => clearInterval(intervalo);
  }, [startSelectedDate, formattedNextDay, endSelectedDate]);

  return calls;
};

export default useCalls;
