import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MdAdd, MdDelete } from "react-icons/md";
import { headStyles, cellStyles } from "./TableStyles";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  Select,
  AccordionIcon,
  AccordionPanel,
  Badge,
} from "@chakra-ui/react";
import { showToast } from "../../commons/SweetAlertToast";
import axios from "axios";
import { Link } from "react-router-dom";
const Tickets = ({ ticketsByCustomer }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [editedData, setEditedData] = useState(null);
  const customers = useSelector((state) => state.clients);
  const admins = useSelector((state) => state.admins);
  const [customerNotes, setCustomerNotes] = useState([]);
  const [internalNotes, setInternalNotes] = useState([]);
  const [status, setStatus] = useState("Open");

  useEffect(() => {
    axios.get("https://api.nevtis.com/interaction/tickets/all").then((res) => {
      setData(res.data);
    });
  }, [data]);

  useEffect(() => {
    if (selectedData) {
      setEditedData({ ...selectedData });
      setCustomerNotes(selectedData.customerNotes || []);
      setInternalNotes(selectedData.internalNotes || []);
    }
  }, [selectedData]);

  const handleEdit = (rowData) => {
    setSelectedData(rowData);
    onOpen();
  };

  const handleNoteChange = (index, value, type) => {
    if (type === "customerNotes") {
      const updatedNotes = [...customerNotes];
      updatedNotes[index] = value;
      setCustomerNotes(updatedNotes);
    } else {
      const updatedNotes = [...internalNotes];
      updatedNotes[index] = value;
      setInternalNotes(updatedNotes);
    }
  };

  const handleAddNote = (type) => {
    if (type === "customerNotes") {
      setCustomerNotes([...customerNotes, ""]);
    } else {
      setInternalNotes([...internalNotes, ""]);
    }
  };

  const handleDeleteNote = (index, type) => {
    if (type === "customerNotes") {
      const updatedNotes = customerNotes.filter((_, i) => i !== index);
      setCustomerNotes(updatedNotes);
    } else {
      const updatedNotes = internalNotes.filter((_, i) => i !== index);
      setInternalNotes(updatedNotes);
    }
  };

  const handleSave = () => {
    axios
      .put(`https://api.nevtis.com/interaction/tickets/${editedData._id}`, {
        status: editedData.status,
        customerNotes: customerNotes,
        internalNotes: internalNotes,
      })
      .then((resp) => {
        onClose();
        showToast("Ticket updated", "success");
      });
  };

  const findAdmin = (id) => {
    const client = admins.find((cust) => {
      return cust._id === id;
    });
    return client;
  };
  const findCustomers = (id) => {
    const customer = customers.find((cust) => {
      return cust._id === id;
    });
    return customer;
  };

  return (
    <Box mt={4}>
      <Table variant="simple" border="1px" borderColor="gray.300">
        <Thead>
          <Tr border="1px" borderColor="gray.300">
            <Th {...headStyles}>Ticket Number</Th>
            <Th {...headStyles}>Status</Th>
            <Th {...headStyles}>Company Name</Th>
            <Th {...headStyles}>Customer Notes</Th>
            <Th {...headStyles}>Internal Notes</Th>
            <Th {...headStyles}>User</Th>
            <Th {...headStyles} width="10VW">
              Actions
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {ticketsByCustomer.map((rowData) => (
            <Tr key={rowData._id} border="1px" borderColor="gray.300" p={0}>
              <Td {...cellStyles}>{rowData.ticketNumber}</Td>
              <Td {...cellStyles}>
                <Badge colorScheme="green">{rowData.status}</Badge>
              </Td>
              <Td {...cellStyles}>
                <Link
                  to={`https://adminspace.nevtis.com/client/${rowData.company}`}
                >
                  {findCustomers(rowData.company).companyName}
                </Link>
              </Td>
              <Td {...cellStyles}>{rowData.customerNotes?.join(", ")}</Td>
              <Td {...cellStyles}>{rowData.internalNotes?.join(", ")}</Td>
              <Td {...cellStyles}>{findAdmin(rowData.user).name}</Td>
              <Td>
                <Button onClick={() => handleEdit(rowData)}>Edit</Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Ticket</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Customer Notes Accordion */}
            <Accordion allowToggle>
              <AccordionItem>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Customer Notes
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Flex>
                    <Button
                      size={"xs"}
                      colorScheme="blue"
                      onClick={() => handleAddNote("customerNotes")}
                    >
                      <MdAdd />
                    </Button>
                  </Flex>
                  {customerNotes.map((note, index) => (
                    <Flex key={index} alignItems="center" mb={2}>
                      <Input
                        value={note}
                        onChange={(e) =>
                          handleNoteChange(
                            index,
                            e.target.value,
                            "customerNotes"
                          )
                        }
                        placeholder={`Note ${index + 1}`}
                      />
                      <Button
                        size={"xs"}
                        colorScheme="red"
                        ml={2}
                        onClick={() => handleDeleteNote(index, "customerNotes")}
                      >
                        <MdDelete />
                      </Button>
                    </Flex>
                  ))}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>

            {/* Internal Notes Accordion */}
            <Accordion allowToggle mt={4}>
              <AccordionItem>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Internal Notes
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Flex>
                    <Button
                      size={"xs"}
                      colorScheme="blue"
                      onClick={() => handleAddNote("internalNotes")}
                    >
                      <MdAdd />
                    </Button>
                  </Flex>
                  {internalNotes.map((note, index) => (
                    <Flex key={index} alignItems="center" mb={2}>
                      <Input
                        value={note}
                        onChange={(e) =>
                          handleNoteChange(
                            index,
                            e.target.value,
                            "internalNotes"
                          )
                        }
                        placeholder={`Internal Note ${index + 1}`}
                      />
                      <Button
                        size={"xs"}
                        colorScheme="red"
                        ml={2}
                        onClick={() => handleDeleteNote(index, "internalNotes")}
                      >
                        <MdDelete />
                      </Button>
                    </Flex>
                  ))}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>

            {/* Status */}
            <FormControl mt={4}>
              <FormLabel>Status:</FormLabel>
              <Select
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                  setEditedData((prev) => ({
                    ...prev,
                    status: e.target.value,
                  }));
                }}
              >
                <option value="Open">Open</option>
                <option value="In Progress">In Progress</option>
                <option value="Closed">Closed</option>
                <option value="Pending">Pending</option>
              </Select>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" onClick={handleSave}>
              Save
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Tickets;
