import axios from "axios";
import { createAsyncThunk, createReducer } from "@reduxjs/toolkit";

const initialState = [];

export const getAllTickets = createAsyncThunk("GET_ALL_TICKETS", async () => {
  const response = await axios.get(
    `https://api.nevtis.com/interaction/tickets/all`
  );
  return response.data;
});

const ticketsReducer = createReducer(initialState, {
  [getAllTickets.fulfilled]: (state, action) => {
    return action.payload;
  },
});

export default ticketsReducer;
