import React, { useEffect, useState, useCallback } from "react";
import Loading from "../../../commons/general/Loading";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  Box,
  useMediaQuery,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import TableExtensions from "./TableExtensions";
import TableSoftphoneUsers from "../Gest Softphone/TableSoftphoneUsers";
import TableDestinationsByCustomer from "./TableDestinationsByCustomer";
import TableRingGroups from "./TableRingGroups";
import TableCustomerCalls from "./TableCustomerCalls";
import axios from "axios";
import { URL_FUSIONPBX } from "../../../config/apiBaseURL";
import { servers } from "../../../config/serversFusionPbx";
import LiveCalls from "../../../commons/admin/LiveCalls";

const NevtisPbx = ({ customer, extensionsByCustomer }) => {
  const allSoftphoneUsers = useSelector((state) => state.softphoneUsers);
  const softphoneUserByCompany = allSoftphoneUsers.filter(
    (item) => item.company._id === customer._id
  );
  const [destinations, setDestinations] = useState([]);
  const [ringGroup, setRingGroup] = useState([]);
  const [calls, setCalls] = useState([]);
  const [error, setError] = useState(null);
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  const getServerValue = useCallback(() => {
    const server = servers.find((server) => server.url === customer.serverURL);
    return server ? server.value : null;
  }, [customer.serverURL]);

  useEffect(() => {
    if (!customer?.domain?.domain_uuid || !getServerValue()) return;

    axios
      .get(
        `${URL_FUSIONPBX}/destinations/${getServerValue()}/getAllDestinationsByDomain/${customer.domain.domain_uuid}/all`
      )
      .then((resp) => {
        setDestinations(resp.data);
      })
      .catch(() => {
        setError("No data, please contact support");
      });
  }, [customer.domain.domain_uuid, getServerValue]);

  useEffect(() => {
    if (!customer?.domain?.domain_uuid || !getServerValue()) return;

    axios
      .get(
        `${URL_FUSIONPBX}/ring-groups/${getServerValue()}/getAllRingGroupsByDomain/${customer.domain.domain_uuid}/all`
      )
      .then((resp) => {
        setRingGroup(resp.data);
      })
      .catch(() => {
        setError("No data, please contact support");
      });
  }, [customer.domain.domain_uuid, getServerValue]);

  useEffect(() => {
    if (!customer?.domain?.domain_uuid || !getServerValue()) return;

    axios
      .get(
        `${URL_FUSIONPBX}/calls/${getServerValue()}/getCallsByDomain/${customer.domain.domain_uuid}`
      )
      .then((resp) => {
        setCalls(resp.data);
      })
      .catch(() => {
        setError("No data, please contact support");
      });
  }, [customer.domain.domain_uuid, getServerValue]);

  const extensionesAsignadas = extensionsByCustomer.map((extension) => {
    const softphone = allSoftphoneUsers.find(
      (user) => user.extension.extension_uuid === extension.extension_uuid
    );
    return {
      extension_number: extension.extension,
      effective_caller_id_name: extension.effective_caller_id_name,
      outbound_caller_id_number: extension.outbound_caller_id_number,
      outbound_caller_id_name: extension.outbound_caller_id_name,
      softphone_user: softphone ? softphone : null,
    };
  });

  if (!customer) {
    return <Loading />;
  }

  if (error) {
    return (
      <Text textAlign={"center"} color={"nevtis.1"} fontWeight={"bold"} mt={5}>
        {error}
      </Text>
    );
  }

  return (
    <Tabs variant="soft-rounded" colorScheme="orange" mt={4}>
      <TabList overflowX={isLargerThan768 ? "visible" : "auto"}>
        <Tab>Live Calls</Tab>
        <Tab>Recorded Calls</Tab>
        <Tab>Softphone Users ({softphoneUserByCompany.length})</Tab>
        <Tab>Extensions ({extensionesAsignadas.length})</Tab>
        <Tab>Destinations ({destinations.length})</Tab>
        <Tab>Ring Groups ({ringGroup.length})</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <Box mt={10}>
            <LiveCalls customer={customer} />
          </Box>
        </TabPanel>
        <TabPanel>
          <TableCustomerCalls calls={calls} customer={customer} />
        </TabPanel>
        <TabPanel>
          <TableSoftphoneUsers
            extensionsByCustomer={extensionsByCustomer}
            customer={customer}
            softphoneUsers={softphoneUserByCompany}
          />
        </TabPanel>
        <TabPanel>
          <TableExtensions extensionesAsignadas={extensionesAsignadas} />
        </TabPanel>
        <TabPanel>
          <TableDestinationsByCustomer destinations={destinations} />
        </TabPanel>
        <TabPanel>
          <TableRingGroups ringGroup={ringGroup} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default NevtisPbx;
