import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Center, Flex, IconButton, Tooltip } from "@chakra-ui/react";
import { FiSettings, FiUserCheck } from "react-icons/fi";
import { RiUserSettingsLine } from "react-icons/ri";
import { AiOutlineHome, AiOutlineSchedule } from "react-icons/ai";
import { FiUsers } from "react-icons/fi";
import { MdOutlineStorefront } from "react-icons/md";
/* import { useSelector } from "react-redux";
import { Badge } from "@chakra-ui/react";
import { BiBriefcaseAlt } from "react-icons/bi";
import { HiOutlineChatBubbleLeftRight } from "react-icons/hi2"; */

const Sidebar = () => {
  const path = useLocation().pathname.slice(1);
  /* const notifications = useSelector((state) => state.chats.notifications); */

  const handleMouseEnter = () => {};

  const handleMouseLeave = () => {};

  return (
    <Flex
      mr={5}
      h="620px"
      pb={2}
      marginTop="2.2vh"
      boxShadow="0 4px 12px 0 rgba(0,0,0,0.5)"
      borderTopRightRadius="15px"
      borderBottomRightRadius="15px"
      w={"45px"}
      flexDir="column"
      justifyContent="space-around"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Flex p="5%" flexDir="column" alignItems="center" as="nav">
        <Link to="/">
          <Tooltip bg={"nevtis.1"} label="Home" aria-label="A tooltip">
            <Center>
              <IconButton
                background={path === "" ? "rgba(255,134,0,0.3)" : "none"}
                mt={3}
                fontSize="2xl"
                color="gray.500"
                _hover={{ textDecor: "none", backgroundColor: "#ffff" }}
                icon={<AiOutlineHome color="nevtis.1" />}
              />
            </Center>
          </Tooltip>
        </Link>

        <>
          <Link to="/client">
            <Tooltip bg={"nevtis.1"} label="Customer" aria-label="A tooltip">
              <Center>
                <IconButton
                  background={
                    path === "client" ? "rgba(255,134,0,0.3)" : "none"
                  }
                  mt={3}
                  fontSize="2xl"
                  color="gray.500"
                  _hover={{ textDecor: "none", backgroundColor: "#ffff" }}
                  icon={<FiUsers />}
                />
              </Center>
            </Tooltip>
          </Link>

          <Link to="/admin">
            <Tooltip bg={"nevtis.1"} label="Admin" aria-label="A tooltip">
              <IconButton
                background={path === "admin" ? "rgba(255,134,0,0.3)" : "none"}
                mt={3}
                fontSize="2xl"
                color="gray.500"
                _hover={{ textDecor: "none", backgroundColor: "#ffff" }}
                icon={<RiUserSettingsLine />}
              />
            </Tooltip>
          </Link>

          <Link to="/seller">
            <Tooltip bg={"nevtis.1"} label="Seller" aria-label="A tooltip">
              <Center>
                <IconButton
                  background={
                    path === "seller" ? "rgba(255,134,0,0.3)" : "none"
                  }
                  mt={3}
                  fontSize="2xl"
                  color="gray.500"
                  _hover={{ textDecor: "none", backgroundColor: "#ffff" }}
                  icon={<FiUserCheck />}
                />
              </Center>
            </Tooltip>
          </Link>
          <Link to="/schedule">
            <Tooltip
              bg={"nevtis.1"}
              label="Service Calls"
              aria-label="A tooltip"
            >
              <Center>
                <IconButton
                  background={
                    path === "schedule" ? "rgba(255,134,0,0.3)" : "none"
                  }
                  mt={3}
                  fontSize="2xl"
                  color="gray.500"
                  _hover={{ textDecor: "none", backgroundColor: "#ffff" }}
                  icon={<AiOutlineSchedule />}
                />
              </Center>
            </Tooltip>
          </Link>

          <Link to="/marketplace">
            <Tooltip bg={"nevtis.1"} label="Marketplace" aria-label="A tooltip">
              <Center>
                <IconButton
                  background={
                    path === "marketplace" ? "rgba(255,134,0,0.3)" : "none"
                  }
                  mt={3}
                  fontSize="2xl"
                  color="gray.500"
                  _hover={{ textDecor: "none", backgroundColor: "#ffff" }}
                  icon={<MdOutlineStorefront />}
                />
              </Center>
            </Tooltip>
          </Link>

          {/*           <Link to="/service">
            <Tooltip bg={"nevtis.1"} label="Services" aria-label="A tooltip">
              <Center>
                <IconButton
                  background={
                    path === "service" ? "rgba(255,134,0,0.3)" : "none"
                  }
                  mt={3}
                  fontSize="2xl"
                  color="gray.500"
                  _hover={{ textDecor: "none", backgroundColor: "#ffff" }}
                  icon={<BiBriefcaseAlt />}
                />
              </Center>
            </Tooltip>
          </Link> */}
        </>

        {/*         <Flex p="5%" flexDir="column" alignItems="center" as="nav">
          
          <Link to="/chat">
            <Tooltip bg={"nevtis.1"} label="Chat" aria-label="A tooltip">
              <Center position="relative">
                <IconButton
                  background={path === "chat" ? "rgba(255,134,0,0.3)" : "none"}
                  mt={3}
                  fontSize="2xl"
                  color="gray.500"
                  _hover={{ textDecor: "none", backgroundColor: "#ffff" }}
                  icon={<HiOutlineChatBubbleLeftRight />}
                />
                
                {notifications.length > 0 && (
                  <Badge
                    position="absolute"
                    right="-2px"
                    colorScheme="red"
                    bg="rgba(255, 134, 0, 0.8)" // Establece el color de fondo con rgba
                    borderRadius="full"
                  >
                    {notifications.length}
                  </Badge>
                )}
              </Center>
            </Tooltip>
          </Link>
        </Flex> */}

        <Link to="/settings">
          <Tooltip bg={"nevtis.1"} label="Settings" aria-label="A tooltip">
            <Center>
              <IconButton
                background={
                  path === "settings" ? "rgba(255,134,0,0.3)" : "none"
                }
                mt={3}
                fontSize="2xl"
                color="gray.500"
                _hover={{ textDecor: "none", backgroundColor: "#ffff" }}
                icon={<FiSettings />}
              />
            </Center>
          </Tooltip>
        </Link>
      </Flex>
    </Flex>
  );
};

export default Sidebar;
